import React from 'react';
import styled from 'styled-components';
import HealthLogo from '@assets/images/lookout-health-logo-home.png';
import HealthCities from '@assets/images/lookout-health-cities.png';
import HealthClients from '@assets/images/lookout-health-clients.png';
import HealthLogoBackground from '@assets/images/wholebg2.png';

const Container = styled.section`
  background: url(${HealthLogoBackground}) repeat-x;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    // height: auto;
    // padding: 20px 0;
    // display: none;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  max-width: 1000px;

  h1 {
    margin-bottom: 20px;

    img {
      width: 350px;

      @media (max-width: 600px) {
        width: 260px;
      }
    }
  }

  p {
    color: white;
    opacity: 0.9;
    max-width: 1000px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  @media (max-width: 600px) {
    // display: none;
  }
`;

const Cities = styled.div`
  margin-bottom: 20px;

  img {
    width: 200px;
  }
`;

const Clients = styled.div`
  img {
    width: 100%;
  }
  margin-bottom: 20px;
`;

const ContactLink = styled.a`
  color: #79aad3ff;
  opacity: 0.9;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export default function HeroPage({ page }) {
  return (
    <Container>
      <Content>
        <h1>
          <img src={HealthLogo} alt="Lookout" />
        </h1>
        <div>
          <p>
            Positioned at the forefront of Pharma and Health, we are writers,
            producers, directors, and visionaries. As a globally connected
            production company, we redefine stories, from patient testimonials
            and HCP tales to consumer campaigns. Beyond crafting engaging live
            action and animated narratives, we also excel in building compelling
            photography.
            <br />
            <br />
            With a vast network of collaborators at our side, we pour our
            passion into visuals that resonate, inform, and captivate,
            highlighting our diverse expertise.
          </p>
        </div>
        <Cities>
          <img src={HealthCities} alt="Toronto | Miami" />
        </Cities>
        <Clients>
          <img src={HealthClients} alt="Lookout Clients" />
        </Clients>
        <ContactLink href="mailto:mailto:info@lookoutcontent.com">
          info@lookoutcontent.com
        </ContactLink>
      </Content>
    </Container>
  );
}
